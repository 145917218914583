.c-option--fieldset,
.yesno-fieldset {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
}

.c-option--triage {
  cursor: pointer !important;
  -webkit-transition: color 0.3s, background-color 0.3s;
  transition: color 0.3s, background-color 0.3s;
  position: relative;
  font-size: 16px;
  display: inline-block;
  background: $primary-unselected-bg;
  color: $primary-unselected-txt;
  width: 100%;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: $radius-default;
  box-shadow: $input-shadow;
  border-width: $borderWidth-DEFAULT;
  border-color: $borderColor-grey-200;

  input {
    float: right;
    margin-top: 5px;
    margin-left: 5px;
    opacity: 0;
  }

  &.selected {
    background: $primary-selected-bg;
    color: $primary-selected-txt;
    font-weight: 500;

    .triage-cb {
      &:after {
        opacity: 1;
      }
    }
  }

  .triage-cb {
    cursor: pointer !important;
    background: $input-bg;
    border-radius: 3px;
    border: $input-border;
    display: inline-block;
    width: $input-size;
    height: $input-size;
    position: absolute;
    right: 15px;
    top: 20px;

    &.dont-show {
      opacity: 0.01;
      height: 1px;
      width: 1px;
      overflow: hidden;
    }

    &:after {
      cursor: pointer !important;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      background-image: url(/assets/icons/tick-blue.svg);
      background-size: contain;
      background-repeat: no-repeat;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      position: absolute;
      content: ' ';
      width: 10px;
      height: 10px;
      opacity: 0;
    }
  }
}

.c-btn[disabled] {
  background-color: $disabled-bg !important;
  border-color: $disabled-bg !important;
  color: $disabled-txt !important;
}

.c-btn--triage {
  margin-top: 48px;
}

.yesno-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-items: center;
  margin-bottom: 24px;

  .c-option--triage-yn {
    display: inline-block;
    padding: 12px 8px 8px 8px;
    width: 48px;
    text-align: center;
    margin: 0 4px;
    box-sizing: border-box;
    font-size: 14px;
  }
}
