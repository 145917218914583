.triage-legend {
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 2px;
  text-align: left;
  color: $legend-color;
  margin-bottom: 16px;
}

.triage-sub-legend {
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  text-align: left;
  color: $primary-blue;
  margin-bottom: 32px;
}

.triage-title {
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: $title-color;
  margin-bottom: 32px;

  &.bigboy {
    font-size: 36px;
    font-weight: 400;
    line-height: 1.3;

    @media (min-width: 600px) {
      font-size: 54px;
      line-height: 1.5;
    }
  }
}

.triage-description-limit {
  text-align: right;
  margin-top: -1.5em;
  margin-right: 0.5em;
  color: #777;
  font-size: 1em;
}

.triage-description-ta {
  border-radius: $radius-default;
  overflow: hidden;
  border: solid 1px $description-border-color;
  box-shadow: $input-shadow;
  resize: none;
  padding: 16px 16px 32px 16px;

  &::placeholder {
    color: #90a4ae;
  }
}

.progress-counter {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
}

.yesno-question {
  font-weight: 300;
  line-height: 26px;
  color: #57575c;
  margin-right: 8px;
  display: inline-block;
  width: 65%;
  align-self: center;
  font-size: 16px;
}

.emergency-cta {
  color: red;
  font-size: 0.9em;
  max-width: 391px;
  width: 100%;
  margin: 24px auto;
  font-weight: 400;
  text-align: center;
}
