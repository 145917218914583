@use '@angular/material' as mat;
@import '@pushdr/stylekit/scss/stylekit.scss';
@import '@pushdr/stylekit/scss/tailwind-vars';
@import './assets/triage';
@import '@angular/material/theming';
@include mat.core();
$candy-app-primary: mat.define-palette(mat.$indigo-palette, A100);
$candy-app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$candy-app-warn: mat.define-palette(mat.$red-palette);
$candy-app-theme: mat.define-light-theme($candy-app-primary, $candy-app-accent, $candy-app-warn);
@include mat.all-component-themes($candy-app-theme);

$custom-typography: mat.define-typography-config(
  $font-family: '"Circular", sans-serif',
);

@include mat.all-component-typographies($custom-typography);

.devLinks-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  text-align: left;
  z-index: 999999;
  transition: left 250ms ease-in-out;
  background-color: rgba(0, 0, 0, 0.8);
  width: 350px;

  hr {
    margin-right: 20px;
  }

  &.slide-menu {
    left: -350px;
    background-color: transparent;

    .box-shadow-menu:before {
      background: black;
      -webkit-box-shadow: 0 0.25em 0 0 black, 0 0.5em 0 0 black;
      box-shadow: 0 0.5em 0 0 black, 0 1em 0 0 black;
    }
  }

  &:not(.slide-menu) {
    .box-shadow-menu {
      right: 0.3em;
    }
  }

  &.min .no-min {
    display: none;
  }

  .box-shadow-menu {
    position: absolute;
    padding-left: 1.25em;
    right: -2.7em;
    top: 0.3em;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }

    min-height: 2em;
    min-width: 2em;
  }

  .box-shadow-menu:before {
    content: '';
    position: absolute;
    left: -0.4em;
    top: 0.25em;
    width: 2em;
    height: 0.25em;
    background: white;
    -webkit-box-shadow: 0 0.25em 0 0 white, 0 0.5em 0 0 white;
    box-shadow: 0 0.5em 0 0 white, 0 1em 0 0 white;
  }

  .devLinks {
    text-transform: uppercase;
    padding-right: 45px;
    color: #9b9b9b;
    height: 100%;
    overflow-y: auto;
    margin-top: 0;
    padding-top: 2em;

    ul {
      padding-left: 10px;
    }

    li {
      margin-bottom: 0px;

      &:before {
        content: '';
      }
    }

    .link {
      text-transform: capitalize;
      color: #ddd;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      &.active-link {
        font-weight: bolder;
        color: #fff;

        &:after {
          content: ' ✓';
        }
      }
    }
  }
}

// overrides
$listview-edit-icon-size: 25px;

.c-btn {
  max-width: 100%;
}

.c-btn-anchor-underlined {
  color: $black !important;
  text-decoration: underline;
}

.c-listview {
  padding-left: 0;
}

.c-listview__item {
  padding-left: 0;
  margin-bottom: 0;
  padding-top: $base-spacing-unit--tiny;
  padding-bottom: $base-spacing-unit--tiny;
  border-bottom: 1px solid theme-color(grey-200);

  &:before {
    display: none;
  }

  &:first-child {
    border-top: 1px solid theme-color(grey-200);
  }

  > h2,
  > p {
    padding-left: $base-spacing-unit--tiny;
  }
}

.c-listview__item {
  .c-listview--booking & {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding-left: $base-spacing-unit--tiny;
    padding-right: $base-spacing-unit--tiny;
    padding-top: $base-spacing-unit--small;
    padding-bottom: $base-spacing-unit--small;
    box-shadow: 0px 1px 16px theme-color(grey-200);
    margin-bottom: $base-spacing-unit--tiny;
    border-radius: 3px;
    border: none;

    &:after {
      height: auto;
      width: auto;
      background-image: none;
      background-size: auto;
      transform: none;
      color: theme-color(primary);
      font-weight: $font-weight-normal;
    }

    &.c-listview__item--select {
      &:after {
        content: 'Select';
      }
    }

    &.c-listview__item--book {
      &:after {
        content: 'Book';
      }
    }

    &:hover {
      cursor: pointer;
      background-color: theme-color(grey-200);
      transition: background-color 100ms ease-out;
    }

    transition: background-color 100ms ease-in;
  }
}

.c-listview__heading {
  font-weight: $font-weight-normal;
  margin: 0;
  padding-left: 0;
}

.c-jumbotron {
  background: theme-color(grey-300);
  border-radius: $c-form-part-border-radius;
  padding: $base-spacing-unit--tiny;
  margin: $base-spacing-unit--tiny 0;

  button.c-btn.c-btn--primary {
    margin-bottom: 0;
  }
}

h5 {
  margin: $base-spacing-unit--tiny 0;
}

.c-form-part__title {
  outline: none;
}

.u-sr-only {
  outline: none;
}

.u-justify-content-center {
  justify-content: center;
}

.js-modal-open {
  -webkit-overflow-scrolling: touch;
}

.c-modal {
  table {
    @media (max-width: 599px) {
      font-size: 0.8em;
    }
  }

  tr {
    vertical-align: top;
  }
}

// TODO move this into the stylekit
.c-modal__wrap {
  overflow-x: hidden;
  overflow-y: auto;
}

.c-form-part__two-date-field-wrap {
  border-radius: 3px;
  position: relative;
  border: 1px solid #000000;
  max-width: 230px;
  margin-top: 8px;
  margin-right: 20px;

  &:after {
    content: '/';
    font-size: 17px;
    position: absolute;
    bottom: 16px;
    color: #57575c;
    opacity: 0.8;
    left: 50%;
  }

  .c-form-part__two-field.c-form-part__two-field-1 {
    padding-right: 5px;
    padding-left: 5px;
    max-width: 70px;
  }

  .c-form-part__two-field.c-form-part__two-field-2 {
    padding-right: 5px;
    padding-left: 10px;
    max-width: 70px;
  }

  .c-form-part__two-field {
    border: none;
    display: inline-block;
    background-color: transparent;
    margin: 0;
    text-align: center;
  }

  .c-form-part__entry--is-invalid & {
    border-color: #fb5e55;
  }
}

.c-form-part__three-date-field-wrap,
.c-form-part__three-date-field-wrap {
  &:before,
  &:after {
    content: none;
  }
}

.questionnaire-content {
  h4 {
    margin-bottom: $space-0;
    margin-top: $space-4;
  }
  p {
    margin-bottom: $space-2;
  }
}

input[type='text'],
input[type='search'],
input[type='email'],
input[type='date'],
input[type='datetime-local'],
input[type='time'],
input[type='number'],
input[type='password'],
input[type='tel'],
textarea {
  &.ng-invalid.ng-touched {
    border-color: $borderColor-error;
    background-color: $backgroundColor-error-light;
  }
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

ul.green-checked-list {
  li {
    &::before {
      background-image: url('./assets/green_check_circle.svg');
      content: '';
      width: 18px;
      height: 18px;
      background-size: cover;
      top: 2px;
    }
  }
}
