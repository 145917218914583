$blue-light: #2f50fd;
$blue-dark: #19237e;
$blue-eyebleed: #1ee9b6;

// Triage buttons
$primary-blue: $blue-light;
$primary-selected-bg: $blue-dark;
$primary-unselected-bg: #ffffff;
$primary-selected-txt: #ffffff;
$primary-unselected-txt: #263237;

$input-bg: #ffffff;
$input-border: 2px $blue-dark solid;
$input-size: 18px;
$input-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);

// Text
$legend-color: #546f7a;
$title-color: #31393c;
$description-border-color: rgba(#546f7a, 0.2);

// Disabled
$disabled-bg: #90a4ae;
$disabled-txt: #ffffff;

$radius-default: 4px;
