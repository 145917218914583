:host-context(.accessibility-keyboard) {
  *:focus,
  [tabindex]:focus,
  label:focus-within {
    outline: 2px dashed $primary-blue;
    outline-offset: 4px;
    transition: outline 0s, outline-offset 0s !important;
  }
}

form:focus {
  outline: 0px dashed transparent;
}
